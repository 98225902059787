import {
    faChartBar,
    faCheckCircle,
    faInfoCircle,
    faLayerGroup,
    faParking,
    faPlug,
    faPlusCircle,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import { Button, Col, FlexboxGrid, Loader, Message, Panel, Tag } from 'rsuite';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ProgressComponent from '../Map/Components/Progress/ProgressComponent';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import Counter, { counterTypeEnum } from '../../handlers/Counter/Counter';
import { CreateCounterModal } from './Modal/CreateCounterModal';
import { DeleteCounterModal } from './Modal/DeleteCounterModal';
import { ExternalApi } from '../../handlers/Counter/ExternalApi';
import { DeleteExternalApiModal } from './Modal/DeleteExternalApiModal';
import { CreateExternalApiModal } from './Modal/CreateExternalApiModal';
import { ExternalApiDrawer } from './Drawer/indexExternalApi';
import { ExternalCounterDrawer } from './Drawer/indexExternalCounter';

type Props = WrappedComponentProps;

type State = {
    eventDrawer: Record<string, any> | null;
    loading: boolean;
    error: boolean;
    counters: Array<Record<string, any>>;
    externalCounters: Array<Record<string, any>>;
    isEventDrawerOpen: boolean;
    isCreateEventModalOpen: boolean;
};

interface ExternalApiDrawerInterface {
    externalApi?: ExternalApi;
    isOpen: boolean;
}

interface ExternalCounterDrawerInterface {
    counter?: Counter;
    isOpen: boolean;
}

export const ListCounters = () => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const [counters, setCounters] = React.useState<Counter[]>([]);
    const [externalApis, setExternalApis] = React.useState<ExternalApi[]>([]);
    const [selectedInternalCounters, setSelectedInternalCounters] = React.useState<Counter[]>([]);
    const [selectedExternalCounters, setSelectedExternalCounters] = React.useState<Counter[]>([]);
    const [selectedExternalApis, setSelectedExternalApis] = React.useState<ExternalApi[]>([]);
    const [createCounterModalOpen, setCreateCounterModalOpen] = React.useState<boolean>(false);
    const [createExternalApiModalOpen, setCreateExternalApiModalOpen] = React.useState<boolean>(false);
    const [showDeleteCountersModalOpen, setShowDeleteCountersModalOpen] = React.useState<{
        internal: boolean;
        external: boolean;
    }>({ internal: false, external: false });
    const [showDeleteExternalApiModalOpen, setShowDeleteExternalApiModalOpen] = React.useState<boolean>(false);
    const [cleared, setCleared] = React.useState<boolean>(false);
    //---
    const [externalApiDrawer, setExternalApiDrawer] = React.useState<ExternalApiDrawerInterface>({
        isOpen: false,
    });
    const [externalCounterDrawer, setExternalCounterDrawer] = React.useState<ExternalCounterDrawerInterface>({
        isOpen: false,
    });
    /**
     * Load counters
     */
    React.useEffect(() => {
        loadCounters();
        webSocketService.joinRoom('counter');
    }, []);

    /**
     * Handle WebSocket event
     */
    React.useEffect(() => {
        webSocketService.onEvent('counter:update', handleWebSocket);

        return function cleanup() {
            webSocketService.offEvent('counter:update', handleWebSocket);
        };
    }, [!isLoading]);

    /**
     * Function to handle WebSocket event
     * @param data : Counter
     */
    const handleWebSocket = (data: Counter) => {
        const localCounters = [...counters];
        const foundId = localCounters.findIndex(counter => counter.id === data.id);
        //---
        if (foundId != -1) {
            localCounters[foundId].counterValue = data.counterValue;
            if (localCounters[foundId].type == counterTypeEnum.EXTERNAL) {
                localCounters[foundId].externalCounter.externalConnectionStatus = data.externalCounter.externalConnectionStatus;
                localCounters[foundId].externalCounter.lastReceiveData = data.externalCounter.lastReceiveData;
                localCounters[foundId].externalCounter.lastTimeReceiveData = data.externalCounter.lastTimeReceiveData;
            }

            setCounters(localCounters);
        }
    };

    /**
     * Function to load counters
     */
    const loadCounters = () => {
        setIsLoading(true);

        axiosService
            .getAxios()
            .get<Counter[]>('/counters', {
                headers: authHeader(),
            })
            .then(response => {
                setCounters(response.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
                // Ask data to initialize all counters
                axiosService.getAxios().get('/counters/status', { headers: authHeader() });
                loadExternalApi();
            });
    };

    const loadExternalApi = () => {
        setIsLoading(true);

        axiosService
            .getAxios()
            .get<ExternalApi[]>('/counters/external-api/get', {
                headers: authHeader(),
            })
            .then(response => {
                setExternalApis(response.data);
            })
            .catch(err => {
                console.log(err);
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const reloadCounter = () => {
        loadCounters();
    };

    const toggleCleared = () => {
        setCleared(!cleared);
    };

    const handleCloseDeleteModal = (deleted: boolean) => {
        setShowDeleteExternalApiModalOpen(false);
        setShowDeleteCountersModalOpen({ internal: false, external: false });
        if (deleted) {
            toggleCleared();
            reloadCounter();
        }
    };

    /**
     * Define columns for the table of internal counters
     */
    const internalCountersColumns: TableColumn<Counter>[] = [
        {
            name: intl.formatMessage({
                id: 'counter.name',
            }),
            center: true,
            cell: row => (
                <div data-cy="event-list-name" data-tag="allowRowEvents">
                    {row.label}
                </div>
            ),
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'counter.type',
            }),
            center: true,
            cell: row => {
                if (row.type == 'Park') return <FontAwesomeIcon icon={faLayerGroup} color="green" />;
                else if (row.type == 'Level') return <FontAwesomeIcon icon={faLayerGroup} color="blue" />;
                else if (row.type == 'Zone') return <FontAwesomeIcon icon={faParking} />;
                else if (row.type == 'cpt vehicle') return <FontAwesomeIcon icon={faInfoCircle} color="orange" />;
                else {
                    return (
                        <div data-cy="event-list-name" data-tag="allowRowEvents">
                            {row.type}
                        </div>
                    );
                }
            },
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'counter.nbSensors',
            }),
            center: true,
            cell: row => (
                <div data-cy="counter-nbSensors" data-tag="allowRowEvents">
                    {row.nbSensors}
                </div>
            ),
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'counter.values',
            }),
            center: true,
            cell: row =>
                row.counterValue ? (
                    <FlexboxGrid.Item componentClass={Col} className="flexbox-grid-item-counters" xs={24}>
                        <ProgressComponent
                            label={''}
                            total={row.counterValue.all.total}
                            free={row.counterValue.all.free}
                            occupied={row.counterValue.all.occupied}
                            size={12}
                        />
                    </FlexboxGrid.Item>
                ) : (
                    <Loader vertical />
                ),
            grow: 5,
        },
    ];

    /**
     * Define columns for the table of external counters
     */
    const externalCountersColumns: TableColumn<Counter>[] = [
        {
            name: intl.formatMessage({
                id: 'counter.name',
            }),
            center: true,
            cell: row => (
                <div data-cy="event-list-name" data-tag="allowRowEvents">
                    {row.label}
                </div>
            ),
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'counter.externalApi',
            }),
            center: true,
            cell: row => {
                return <Tag color="blue">{row.externalApi ? row.externalApi.label : ''}</Tag>;
            },
            grow: 1,
        },
        {
            name: intl.formatMessage({ id: 'counter.externalStatus' }),
            cell: row => (
                <FontAwesomeIcon
                    icon={faPlug}
                    color={row.externalCounter.externalConnectionStatus ? 'green' : 'red'}
                    size="lg"
                />
            ),
            selector: row => row.externalCounter.externalConnectionStatus,
            center: true,
            sortable: true,
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'counter.enabled',
            }),
            center: true,
            cell: row => (
                <FontAwesomeIcon size="lg" icon={faCheckCircle} color={row.externalCounter.enabled ? 'green' : 'red'} />
            ),
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'counter.values',
            }),
            center: true,
            cell: row =>
                row.counterValue ? (
                    <FlexboxGrid.Item componentClass={Col} className="flexbox-grid-item-counters" xs={24}>
                        <ProgressComponent
                            label={''}
                            total={row.counterValue.all.total}
                            free={row.counterValue.all.free}
                            occupied={row.counterValue.all.occupied}
                            size={12}
                        />
                    </FlexboxGrid.Item>
                ) : (
                    <Loader vertical />
                ),
            grow: 5,
        },
    ];

    /**
     * Define columns for the table of external api
     */
    const externalApisColumns: TableColumn<ExternalApi>[] = [
        {
            name: intl.formatMessage({
                id: 'externalApi.label',
            }),
            center: true,
            cell: row => (
                <div data-cy="event-list-name" data-tag="allowRowEvents">
                    {row.label}
                </div>
            ),
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'externalApi.nbCounters',
            }),
            center: true,
            cell: row => (
                <div data-cy="externalApi-nbCounters" data-tag="allowRowEvents">
                    {row.counters.length}
                </div>
            ),
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'externalApi.createdBy',
            }),
            center: true,
            cell: row => (
                <div data-cy="event-list-name" data-tag="allowRowEvents">
                    {row.createdBy}
                </div>
            ),
            grow: 1,
        },
    ];

    return (
        <>
            <CreateCounterModal
                isOpen={createCounterModalOpen}
                onHide={() => setCreateCounterModalOpen(false)}
                reloadCounter={reloadCounter}
                externalApis={externalApis}
            />

            <CreateExternalApiModal
                isOpen={createExternalApiModalOpen}
                onHide={() => setCreateExternalApiModalOpen(false)}
                reloadCounter={reloadCounter}
            />

            <DeleteCounterModal
                counters={showDeleteCountersModalOpen.internal ? selectedInternalCounters : selectedExternalCounters}
                show={showDeleteCountersModalOpen.external || showDeleteCountersModalOpen.internal}
                onHide={handleCloseDeleteModal}
            />

            <DeleteExternalApiModal
                externalApis={selectedExternalApis}
                show={showDeleteExternalApiModalOpen}
                onHide={handleCloseDeleteModal}
            />

            <ExternalApiDrawer
                isOpen={externalApiDrawer.isOpen}
                onHide={() => setExternalApiDrawer({ isOpen: false })}
                externalApi={externalApiDrawer.externalApi}
                reloadCounters={reloadCounter}
            />

            <ExternalCounterDrawer
                isOpen={externalCounterDrawer.isOpen}
                onHide={() => setExternalCounterDrawer({ isOpen: false })}
                counter={externalCounterDrawer.counter}
                externalApis={externalApis}
                reloadCounters={reloadCounter}
            />

            <Panel
                className="panel-big element-table dashboard-card-header hoverable"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'counters.internal.title' })}
                        tagValue={counters.filter(counter => counter.type != 'external').length}
                        icon={faParking}
                        buttons={[
                            <SecuredFragment
                                authorizedRoles={[rolesConstants.counters.DELETE]}
                                key="counter-internal-delete">
                                <Button
                                    className="margin-right-5"
                                    color="red"
                                    size="sm"
                                    onClick={() => setShowDeleteCountersModalOpen({ internal: true, external: false })}
                                    disabled={isLoading || selectedInternalCounters.length === 0}
                                    data-cy="counter-internal-delete">
                                    <FontAwesomeIcon icon={faTrash} className="margin-right-5" />
                                    <FormattedMessage id="counter.internal.delete" />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <ElementTable
                    columns={internalCountersColumns}
                    data={counters.filter(counter => counter.type != counterTypeEnum.EXTERNAL)}
                    progressPending={isLoading}
                    progressComponent={<Loader backdrop />}
                    // onRowClicked={(row: Counter) => setCounterDrawer({ counter: row, isOpen: true })}
                    selectableRows
                    selectableRowDisabled={row => {
                        return row.type == counterTypeEnum.PARKING || row.type == counterTypeEnum.LEVEL;
                    }}
                    onSelectedRowsChange={rows => setSelectedInternalCounters(rows.selectedRows)}
                    // clearSelectedRows={cleared}
                />
            </Panel>
            <Panel
                className="panel-big element-table dashboard-card-header hoverable"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'counters.external.title' })}
                        tagValue={counters.filter(counter => counter.type == 'external').length}
                        icon={faParking}
                        buttons={[
                            <SecuredFragment
                                authorizedRoles={[rolesConstants.counters.DELETE]}
                                key="counter-external-delete">
                                <Button
                                    className="margin-right-5"
                                    color="red"
                                    size="sm"
                                    onClick={() => setShowDeleteCountersModalOpen({ internal: false, external: true })}
                                    disabled={isLoading || selectedExternalCounters.length === 0}
                                    data-cy="counter-external-delete">
                                    <FontAwesomeIcon icon={faTrash} className="margin-right-5" />
                                    <FormattedMessage id="counter.external.delete" />
                                </Button>
                            </SecuredFragment>,
                            <SecuredFragment
                                authorizedRoles={[rolesConstants.counters.CREATE]}
                                key="counter-external-create">
                                <Button
                                    color="green"
                                    size="sm"
                                    onClick={() => {
                                        setCreateCounterModalOpen(true);
                                    }}
                                    disabled={isLoading}
                                    data-cy="counter-external-add">
                                    <FontAwesomeIcon icon={faPlusCircle} className="margin-right-5" />
                                    <FormattedMessage id="counter.external.add" />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <ElementTable
                    columns={externalCountersColumns}
                    data={counters.filter(counter => counter.type == 'external')}
                    progressPending={isLoading}
                    progressComponent={<Loader backdrop />}
                    onRowClicked={(row: Counter) => setExternalCounterDrawer({ counter: row, isOpen: true })}
                    selectableRows
                    onSelectedRowsChange={rows => setSelectedExternalCounters(rows.selectedRows)}
                    // clearSelectedRows={cleared}
                />
            </Panel>

            <Panel
                className="panel-big element-table dashboard-card-header hoverable"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'externalApi.title' })}
                        tagValue={externalApis.length}
                        icon={faParking}
                        buttons={[
                            <SecuredFragment
                                authorizedRoles={[rolesConstants.counters.DELETE]}
                                key="counter-external-delete">
                                <Button
                                    className="margin-right-5"
                                    color="red"
                                    size="sm"
                                    onClick={() => setShowDeleteExternalApiModalOpen(true)}
                                    disabled={isLoading || selectedExternalApis.length === 0}
                                    data-cy="counter-external-delete">
                                    <FontAwesomeIcon icon={faTrash} className="margin-right-5" />
                                    <FormattedMessage id="counter.external.delete" />
                                </Button>
                            </SecuredFragment>,
                            <SecuredFragment
                                authorizedRoles={[rolesConstants.counters.CREATE]}
                                key="counter-external-create">
                                <Button
                                    color="green"
                                    size="sm"
                                    onClick={() => {
                                        setCreateExternalApiModalOpen(true);
                                    }}
                                    disabled={isLoading}
                                    data-cy="counter-external-add">
                                    <FontAwesomeIcon icon={faPlusCircle} className="margin-right-5" />
                                    <FormattedMessage id="counter.external.add" />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <ElementTable
                    columns={externalApisColumns}
                    data={externalApis}
                    progressPending={isLoading}
                    progressComponent={<Loader backdrop />}
                    onRowClicked={(row: ExternalApi) => setExternalApiDrawer({ externalApi: row, isOpen: true })}
                    selectableRows
                    onSelectedRowsChange={rows => setSelectedExternalApis(rows.selectedRows)}
                    // clearSelectedRows={cleared}
                />
            </Panel>
        </>
    );
};
