import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Form, FormControl, List } from 'rsuite';

import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { ExternalApi } from '../../../../handlers/Counter/ExternalApi';
import JSONInput from 'react-json-editor-ajrm';

type Props = {
    externalApi: ExternalApi;
    setViewMode: () => void;
};

type FormValue = {
    label: string;
};

export const InformationEditMode = (props: Props) => {
    const intl = useIntl();
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
    const [decodageType, setDecodageType] = React.useState<object>(props.externalApi.decodageType);
    const [jsonIsValid, setJsonIsValid] = React.useState<boolean>(true);

    const [formValue, setFormValue] = React.useState<FormValue>({
        label: props.externalApi.label,
    });

    const handleCancel = () => {
        setFormValue({
            label: props.externalApi.label,
        });

        props.setViewMode();
    };

    const handleValid = () => {
        setIsUpdating(true);

        axiosService
            .getAxios()
            .put<ExternalApi>(
                '/counters/external-api/',
                {
                    id: props.externalApi.id,
                    label: formValue.label,
                    decodageType: decodageType,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(externalApiResponse => {
                // Update externalApi
                props.externalApi.label = externalApiResponse.data.label;
                props.externalApi.decodageType = externalApiResponse.data.decodageType;
                props.externalApi.updatedBy = externalApiResponse.data.updatedBy;
                props.externalApi.updatedAt = externalApiResponse.data.updatedAt;

                Alert.success(intl.formatMessage({ id: 'externalApi.update.success' }));

                setIsUpdating(false);

                props.setViewMode();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'externalApi.update.error' }));

                setIsUpdating(false);
            });
    };

    return (
        <Form formValue={formValue} fluid onChange={formValue => setFormValue(formValue as FormValue)}>
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="externalApi.information.name" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="label" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DECODAGE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="externalApi.information.decodageType" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {/* ExternalApi */}
                            <JSONInput
                                id="decodeType_json"
                                placeholder={decodageType}
                                onChange={data => {
                                    try {
                                        setDecodageType(JSON.parse(data.json));
                                        setJsonIsValid(true);
                                    } catch (e) {
                                        console.error(e);
                                        setJsonIsValid(false);
                                    }
                                }}
                                // height="450px"
                                // width="100%"
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button color="red" onClick={handleCancel} disabled={isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="green" onClick={handleValid} loading={isUpdating} disabled={!jsonIsValid}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );
};
